import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import User from "../components/User";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useNavigate } from "react-router-dom";

const Accounts = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [triggerRefresh, setTriggerRefresh] = useState(0);

  useEffect(() => {
    if (user && user.role && user.role == "admin") {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      axios
        .get("/api/users", config)
        .then((response) => {
          setUsers(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [triggerRefresh]);

  const approveUser = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    axios.put("/api/users/" + id, { isApproved: true }, config);
    setTriggerRefresh(triggerRefresh + 1);
    window.location.reload(false);
  };

  const rejectUser = (id) => {
    const config = {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };
    axios.delete("/api/users/" + id, config);
    setTriggerRefresh(triggerRefresh + 1);
    window.location.reload(false);
  };

  if (!user || !user.role || user.role != "admin") {
    return <div>You are not authorized to view this page.</div>;
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        {/* <p></p> */}
        <h2 >Accounts</h2>
        {/* <Popup
          trigger={<button className="btn"> Create Account</button>}
          position="right center"
        >
          <div>
            <button
              onClick={() => {
                navigate("/register-artist");
              }}
            >
              Create Artist
            </button>
            <br />
            <button
              onClick={() => {
                navigate("/register-admin");
              }}
            >
              Create Admin
            </button>
          </div>
        </Popup> */}
      </div>
      <div
        className="form-container"
        style={{ justifyContent: "space-evenly", alignItems: "center" }}
      >
        <p style={{ marginRight: "20vw", fontWeight: "bold" }}>Account Name</p>
        <p style={{ fontWeight: "bold" }}>Email</p>
        <p style={{ marginLeft: "20vw", fontWeight: "bold" }}>Actions</p>
      </div>
      {users.map((theUser) => (
        <div
          key={theUser._id}
          className="form-container"
          style={{ alignItems: "center", justifyContent: "space-evenly" }}
        >
          <p style={{ marginRight: "20vw" }}>{theUser.name}</p>
          <p>{theUser.email}</p>
          <p style={{ marginLeft: "20vw" }}>
            {theUser.isApproved == "true" && (
              <>
                Approved{" "}
                {user &&
                  user.email == "admin@thesystemrecords.com" &&
                  theUser.email != "admin@thesystemrecords.com" && (
                    <Popup
                      trigger={
                        <button
                          className="btn"
                          style={{ backgroundColor: "red" }}
                        >
                          {" "}
                          Remove
                        </button>
                      }
                      position="left center"
                    >
                      <div>
                        <p>
                          Are you sure you want to remove {theUser.name}'s
                          account? This action cannot be undone and all account
                          info will be lost.
                        </p>
                        <button
                          className="btn"
                          style={{ backgroundColor: "red" }}
                          onClick={() => {
                            if (
                              theUser.role == "admin" &&
                              user &&
                              user.email != "admin@thesystemrecords.com"
                            ) {
                              alert(
                                "You are not authorized to remove admin accounts"
                              );
                            } else if (
                              user.email == "admin@thesystemrecords.com" &&
                              theUser.email == "admin@thesystemrecords.com"
                            ) {
                              alert("You can not remove yourself.");
                            } else {
                              rejectUser(theUser._id);
                            }
                          }}
                        >
                          Remove
                        </button>
                      </div>
                    </Popup>
                  )}
                {user &&
                  user.email != "admin@thesystemrecords.com" &&
                  user.role == "admin" &&
                  theUser.role != "admin" && (
                    <Popup
                      trigger={
                        <button
                          className="btn"
                          style={{ backgroundColor: "red" }}
                        >
                          {" "}
                          Remove
                        </button>
                      }
                      position="left center"
                    >
                      <div>
                        <p>
                          Are you sure you want to remove {theUser.name}'s
                          account? This action cannot be undone and all account
                          info will be lost.
                        </p>
                        <button
                          className="btn"
                          style={{ backgroundColor: "red" }}
                          onClick={() => {
                            if (
                              theUser.role == "admin" &&
                              user &&
                              user.email != "admin@thesystemrecords.com"
                            ) {
                              alert(
                                "You are not authorized to remove admin accounts"
                              );
                            } else if (
                              user.email == "admin@thesystemrecords.com" &&
                              theUser.email == "admin@thesystemrecords.com"
                            ) {
                              alert("You can not remove yourself.");
                            } else {
                              rejectUser(theUser._id);
                            }
                          }}
                        >
                          Remove
                        </button>
                      </div>
                    </Popup>
                  )}
              </>
            )}
            {/*theUser.isApproved == "false" && <>No</>*/}
          </p>
          {theUser.isApproved == "false" && (
            <div>
              <button
                className="btn"
                style={{ margin: "auto", backgroundColor: "green" }}
                onClick={() => {
                  approveUser(theUser._id);
                }}
              >
                Approve
              </button>
              <br />
              <Popup
                trigger={
                  <button className="btn" style={{ backgroundColor: "red" }}>
                    {" "}
                    Remove
                  </button>
                }
                position="left center"
              >
                <div>
                  <p>
                    Are you sure you want to remove {theUser.name}'s account?
                    This action cannot be undone and all account info will be
                    lost.
                  </p>
                  <button
                    className="btn"
                    style={{ backgroundColor: "red" }}
                    onClick={() => {
                      if (
                        theUser.role == "admin" &&
                        user &&
                        user.email != "admin@thesystemrecords.com"
                      ) {
                        alert(
                          "You are not authorized to remove admin accounts"
                        );
                      } else if (
                        user.email == "admin@thesystemrecords.com" &&
                        theUser.email == "admin@thesystemrecords.com"
                      ) {
                        alert("You can not remove yourself.");
                      } else {
                        rejectUser(theUser._id);
                      }
                    }}
                  >
                    Remove
                  </button>
                </div>
              </Popup>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accounts;
