// storageUtils.js

// Function to update localStorage and trigger an event
export const updateLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
    window.dispatchEvent(new CustomEvent('storageUpdated', { detail: { key, value } }));
  };
  
  // Custom event listener setup
  export const addCustomStorageListener = (callback) => {
    window.addEventListener('storageUpdated', callback);
    return () => window.removeEventListener('storageUpdated', callback);
  };
  