import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import Popup from "reactjs-popup";
import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  Header,
  PageBreak,
  Table,
  TableCell,
  TableRow,
  WidthType,
} from "docx";
import { saveAs } from "file-saver";

function Edit() {
  const { user } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  let { id } = useParams();

  const [formState, setFormState] = useState({
    legalName: "",
    talentAddress: "",
    talentOver18: false,
    manageMentCommission: 20,
    termLength: 1,
    termExtensionLengths: 1,
    sunset: false,
    parentGuardianName: "",
    sunsetLength: 1,
    year1Commission: 75,
    year2Commission: 50,
    year3Commission: 25,
  });

  useEffect(() => {
    async function getContractData(){
        const response = await axios.get(`/api/contracts/${id}`, {
          headers: {
            // Include other headers as needed, for example, an authorization header:
            Authorization: `Bearer ${user.token}`,
          },
        });
        console.log(response);
        setFormState(response.data);
      }
      getContractData();
  }, [])

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (
      name === "talentOver18" || name === "sunset"
    ) {
      setFormState((prev) => ({ ...prev, [name]: checked }));
      // if (name === "futureSongsIncluded" && e.target.checked) {
      //   const futures = Array(1)
      //     .fill()
      //     .map((_, i) => ({
      //       optionNumber: 1,
      //       optionNumberInWords: "",
      //       optionPeriod: "years",
      //       advanceValue: "",
      //       advanceValueInWords: "",
      //       marketingValue: "",
      //       marketingValueInWords: "",
      //     }));
      //   setFormState((prev) => ({
      //     ...prev,
      //     futureSongsNumber: 1,
      //     futures,
      //   }));
      // } else if (name === "futureSongsIncluded" && !e.target.checked) {
      //   const futures = Array(0);
      //   setFormState((prev) => ({
      //     ...prev,
      //     futureSongsNumber: 0,
      //     futures,
      //   }));
      // }
    } else {
      setFormState((prev) => ({
        ...prev,
        [name]: type === "number" ? parseInt(value) : value,
      }));
    }
  };

  const handleSongNameChange = (index, value) => {
    const updatedSongs = [...formState.songs];
    updatedSongs[index].name = value;
    setFormState((prev) => ({ ...prev, songs: updatedSongs }));
  };

  const handleArtistChange = (index, field, value) => {
    const updatedArtists = formState.artists.map((artist, idx) => {
      if (field == "publisher" && value == "other" && idx === index) {
        return {
          ...artist,
          publisher: value,
          ipiNumber: "",
          publisherName: "",
        };
      } else if (field == "publisher" && value == "N/A" && idx === index) {
        return {
          ...artist,
          publisher: value,
          ipiNumber: "N/A",
          publisherName: "N/A",
        };
      }
      if (
        idx === index &&
        field == "dob" &&
        new Date() <
          new Date(
            formState.artists[index].dob.getFullYear() + 18,
            formState.artists[index].dob.getMonth(),
            formState.artists[index].dob.getDate()
          )
      ) {
        return { ...artist, dob: value, parentGuardianName: "" };
      } else if (idx === index && field == "dob") {
        return { ...artist, dob: value, parentGuardianName: "N/A" };
      }
      if (idx === index) {
        return { ...artist, [field]: value };
      }
      return artist;
    });
    setFormState({ ...formState, artists: updatedArtists });
  };

  const handleFuturesChange = (index, field, value) => {
    const updatedFutures = formState.futures.map((future, idx) => {
      if (idx === index) {
        return { ...future, [field]: value };
      }
      return future;
    });
    setFormState({ ...formState, futures: updatedFutures });
  };

  // const handleAddArtist = () => {
  //   const newArtist = {
  //     stageName: "",
  //     legalName: "",
  //     address: "",
  //     dob: new Date(),
  //     publisher: "N/A",
  //     ipiNumber: "",
  //     parentGuardianName: "",
  //   };
  //   setFormState({ ...formState, artists: [...formState.artists, newArtist] });
  // };

  const handleDateChange = (date) => {
    setFormState((prev) => ({ ...prev, agreementDate: date }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submit action

    try {
      const response = await axios.put(`/api/contracts/${id}`, formState, {
        headers: {
          // Include other headers as needed, for example, an authorization header:
          Authorization: `Bearer ${user.token}`,
        },
      });

      console.log(response.data); // Handle the response data as needed
      // Optionally, you might want to redirect the user or clear the form here
      navigate("/");
    } catch (error) {
      console.error(
        "There was an error!",
        error.response ? error.response.data : error.message
      );
      // Handle errors, for example, by displaying a message to the user
    }
  };

  const addSongFields = (e) => {
    const numberOfSongs = parseInt(e.target.value);
    const songs = Array(numberOfSongs)
      .fill()
      .map((_, i) => ({ name: "" }));
    setFormState((prev) => ({ ...prev, numberOfSongs, songs }));
  };

  const addArtistFields = (e) => {
    const numberOfArtists = parseInt(e.target.value);
    const artists = Array(numberOfArtists)
      .fill()
      .map((_, i) => ({
        stageName: "",
        legalName: "",
        address: "",
        dob: new Date(),
        publisher: "N/A",
        publisherName: "N/A",
        ipiNumber: "N/A",
        parentGuardianName: "N/A",
      }));
    setFormState((prev) => ({ ...prev, numberOfArtists, artists }));
  };

  const addFuturesFields = (e) => {
    const numberOfFutures = parseInt(e.target.value);
    const futures = Array(numberOfFutures)
      .fill()
      .map((_, i) => ({
        optionNumber: 1,
        optionNumberInWords: "One",
        optionPeriod: "year",
        advanceValue: 1000,
        advanceValueInWords: "One thousand",
        marketingValue: 3000,
        marketingValueInWords: "Three thousand",
      }));
    setFormState((prev) => ({
      ...prev,
      futureSongsNumber: numberOfFutures,
      futures,
    }));
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        style={{
          maxWidth: "600px",
          margin: "auto",
          fontFamily: "Arial, sans-serif",
        }}
      >
        <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "5px",
              fontWeight: "bold",
            }}
          >
            Talent Legal Name:
            <input
              type="text"
              name="legalName"
              value={formState.legalName}
              onChange={handleInputChange}
              style={{
                width: "100%",
                padding: "10px",
                marginTop: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
          </label>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "5px",
              fontWeight: "bold",
            }}
          >
            Talent Address:
            <input
              type="text"
              name="talentAddress"
              value={formState.talentAddress}
              onChange={handleInputChange}
              style={{
                width: "100%",
                padding: "10px",
                marginTop: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
          </label>
        </div>
        <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "5px",
              fontWeight: "bold",
            }}
          >
            Is the Talent 18 or older? Check for Yes:
            <input
              type="checkbox"
              name="talentOver18"
              checked={formState.talentOver18}
              onChange={handleInputChange}
              style={{ marginLeft: "10px" }}
            />
          </label>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "5px",
              fontWeight: "bold",
            }}
          >
            Management Commission Percentage:
            <input
              type="number"
              min="0"
              max="100"
              name="manageMentCommission"
              value={formState.manageMentCommission}
              onChange={handleInputChange}
              style={{
                width: "100%",
                padding: "10px",
                marginTop: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
          </label>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "5px",
              fontWeight: "bold",
            }}
          >
            Term Length:
            <input
              type="number"
              name="termLength"
              value={formState.termLength}
              onChange={handleInputChange}
              style={{
                width: "100%",
                padding: "10px",
                marginTop: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
          </label>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "5px",
              fontWeight: "bold",
            }}
          >
            Term Extension Length:
            <input
              type="number"
              min="1"
              name="termExtensionLengths"
              value={formState.termExtensionLengths}
              onChange={handleInputChange}
              style={{
                width: "100%",
                padding: "10px",
                marginTop: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
          </label>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "5px",
              fontWeight: "bold",
            }}
          >
            Will there be a sunset?
            <input
              type="checkbox"
              name="sunset"
              checked={formState.sunset}
              onChange={handleInputChange}
              style={{ marginLeft: "10px" }}
            />
          </label>
        </div>

        {!formState.talentOver18 && <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "5px",
              fontWeight: "bold",
            }}
          >
            Talent’s Parental Guardian’s Legal Name
            <input
              type="text"
              name="parentGuardianName"
              value={formState.parentGuardianName}
              onChange={handleInputChange}
              style={{
                width: "100%",
                padding: "10px",
                marginTop: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
          </label>
        </div>}

        {formState.sunset && <><div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "5px",
              fontWeight: "bold",
            }}
          >
            How many years of sunset will there be?
            <input
              type="number"
              min="1"
              max="3"
              name="sunsetLength"
              value={formState.sunsetLength}
              onChange={handleInputChange}
              style={{
                width: "100%",
                padding: "10px",
                marginTop: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
          </label>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "5px",
              fontWeight: "bold",
            }}
          >
            Year 1 Commission Percentage:
            <input
              type="number"
              name="year1Commission"
              value={formState.year1Commission}
              onChange={handleInputChange}
              style={{
                width: "100%",
                padding: "10px",
                marginTop: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
          </label>
        </div>
        {formState.sunsetLength > 1 && <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "5px",
              fontWeight: "bold",
            }}
          >
            Year 2 Commission Percentage:
            <input
              type="number"
              name="year2Commission"
              value={formState.year2Commission}
              onChange={handleInputChange}
              style={{
                width: "100%",
                padding: "10px",
                marginTop: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
          </label>
        </div>}
        {formState.sunsetLength > 2 && <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "5px",
              fontWeight: "bold",
            }}
          >
            Year 3 Commission Percentage:
            <input
              type="number"
              name="year3Commission"
              value={formState.year3Commission}
              onChange={handleInputChange}
              style={{
                width: "100%",
                padding: "10px",
                marginTop: "5px",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
          </label>
        </div>}
        </>}


        <button type="submit" style={{padding: "10px", cursor: "pointer", marginBottom: "20px"}}>Draft Agreement</button>
      </form>
    </div>
  );
}

export default Edit;
