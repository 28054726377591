import { useState, useEffect } from "react";
import { FaSignInAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login, reset } from "../features/auth/authSlice";
import Spinner from "../components/Spinner";
import axios from "axios";
import { set } from "mongoose";
import { updateLocalStorage } from '../storageUtils';
import tst from '../components/tst.png';

function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [twoFactor, setTwoFactor] = useState(false);
  const [twoFactorCode, setTwoFactorCode] = useState("");
  const [sentFlag, setSentFlag] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;

  const { email, password } = formData;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }

    async function generate2FA() {
      // Send post request to generate 2FA code passing the email in the body
      if(!sentFlag){
        await axios.post("/api/generate2FA", {
          email: email,
        });
        setSentFlag(true);
      }
      
    }

    if (!sentFlag && isSuccess && user) {
      // navigate("/");
      //setSentFlag(true);
      setTwoFactor(true);
      generate2FA();
    }

    dispatch(reset());
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  useEffect(() => {
    if (isMobile) {
      alert(
        "For the best experience, we recommend accessing our website on a computer rather than a mobile device."
      );
    }
    // set localstorage for 2fa to false
    updateLocalStorage('2FA', 'false');
  }, []);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const userData = {
      email,
      password,
    };

    dispatch(login(userData));
  };

  const validate2FA = async (e) => {
    e.preventDefault();

    let response = await axios.post("/api/validate2FA", {
      code: twoFactorCode,
      email: email,
    });

    // if the response data is true, set the localstorage for 2FA to true and navigate to the dashboard
    if (response.data) {
      updateLocalStorage('2FA', 'true');
      navigate("/");
    } else {
      // if the response data is false, display an error message
      toast.error("Invalid 2FA code");
    }
  }

  if (isLoading) {
    return <Spinner />;
  }

  if(twoFactor){
    return (
      <div
        style={{
          background: "linear-gradient(to bottom right, #9e1a00, #fcba03)",
          height: "100vh",
          marginTop: "-60px",
          paddingTop: "140px",
        }}
      >
        <div className="login-thing">
          <section className="heading">
            {/*<h1>
              <FaSignInAlt />
              Login
            </h1>*/}
            <div className="logo" style={{ paddingTop: "30px" }}>
              <img
                src={tst}
                width="300px"
              />
            </div>
            <p>Enter the 6-digit code sent to your email.</p>
          </section>
          <section className="form">
            <form onSubmit={validate2FA}>
              <div className="form-group">
                <label htmlFor="text">
                  Code <span style={{ color: "#bd0000" }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="2fa"
                  name="2fa"
                  value={twoFactorCode}
                  placeholder="Enter your 6-digit code"
                  onChange={(e) => setTwoFactorCode(e.target.value)}
                />
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn btn-block"
                  style={{
                    backgroundColor: "#c73c00",
                    border: "none",
                    width: "100%",
                  }}
                >
                  Verify
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        background: "linear-gradient(to bottom right, #a935fc, #4089ff)",
        height: "100vh",
        marginTop: "-60px",
        paddingTop: "140px",
      }}
    >
      <div className="login-thing">
        <section className="heading">
          {/*<h1>
            <FaSignInAlt />
            Login
          </h1>*/}
          <div className="logo" style={{ paddingTop: "30px" }}>
            <img
              src={tst}
              width="300px"
            />
          </div>
          <p>Log In</p>
        </section>
        <section className="form">
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="text">
                Email <span style={{ color: "#bd0000" }}>*</span>
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={email}
                placeholder="Enter your email"
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="text">
                Password <span style={{ color: "#bd0000" }}>*</span>
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                name="password"
                value={password}
                placeholder="Enter your password"
                onChange={onChange}
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-block"
                style={{
                  backgroundColor: "#4089ff",
                  border: "none",
                  width: "100%",
                }}
              >
                Sign In
              </button>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
}

export default Login;
