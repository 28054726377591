import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "../components/Spinner";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import bcrypt from "bcryptjs";
import { FaEdit, FaTrashAlt, FaPlus } from "react-icons/fa";
import EmailEditForm from "../components/EmailEditForm";

function Settings() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let { user } = useSelector((state) => state.auth);

  const [newEmail, setNewEmail] = useState("");
  const [newPass, setNewPass] = useState("");
  const [currentEmail, setCurrentEmail] = useState(user.email);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();

    if (newEmail != "") {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      axios.put("/api/users/" + user._id, { email: newEmail }, config);

      setCurrentEmail(newEmail);
      toast("Email updated.");
      //window.location.reload(false);
    } else {
      toast("Please fill out a new email.");
    }
  };

  const onSubmitPass = (e) => {
    e.preventDefault();

    if (newPass != "") {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      axios.put("/api/users/" + user._id, { password: newPass }, config);

      toast("Password updated.");
      //window.location.reload(false);
    } else {
      toast("Please fill out a new password.");
    }
  };

  useEffect(() => {
    if (!user) {
      navigate("/login");
      console.log("yeetDash");
    }
  }, [user]);

  if (!user) {
    return <p>Your session has expired. Please log back in.</p>;
  }

  if (user && user.isApproved == "false") {
    return (
      <p>
        Your account must be approved by a moderator before you can upload
        albums.
      </p>
    );
  }

  return (
    <div className="dash">
      <ToastContainer />
      <p>Account name: {user.name}</p>
      <p>Email: {currentEmail}</p>

      <br />
      {user && user.role == "admin" && <button
        onClick={() => {
          if (show2) {
            setShow2(false);
          } else {
            setShow2(true);
          }
        }}
      >
        Change Password
      </button>}
      {show2 && (
        <div className="">
          <section className="form">
            <form onSubmit={onSubmitPass}>
              <div className="form-container">
                <div className="form-group">
                  <label htmlFor="text">
                    New Password <span style={{ color: "#bd0000" }}>*</span>
                  </label>
                  <input
                    type="text"
                    name="text"
                    id="text"
                    value={newPass}
                    onChange={(e) => {
                      setNewPass(e.target.value);
                    }}
                  />
                </div>
              </div>
              <button type="submit" className="btn">
                Submit
              </button>
            </form>
          </section>
        </div>
      )}
    </div>
  );
}

export default Settings;
