import {
  FaSignInAlt,
  FaSignOutAlt,
  FaUser,
  FaList,
  FaDashcube,
  FaBell,
} from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { BsFillGearFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout, reset } from "../features/auth/authSlice";
import { getMessages } from "../features/messages/messageSlice";
import { useEffect, useState } from "react";
import { addCustomStorageListener } from '../storageUtils';
// import png from same directory
import tst from './tst.png';

function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { messages, isLoading, isError, message } = useSelector(
    (state) => state.messages
  );

  const [twoFactorEnabled, setTwoFactorEnabled] = useState(localStorage.getItem('2FA') == "true" ? true : false);

  // created useEffect to check localstorage to see if 2FA is enabled
  useEffect(() => {
    const handleStorageUpdate = (event) => {
      if (event.detail.key === '2FA') {
        setTwoFactorEnabled(event.detail.value == "true" ? true : false);
      }
    };

    const removeListener = addCustomStorageListener(handleStorageUpdate);

    // Cleanup
    return () => {
      removeListener();
    };
  }, []);

  useEffect(() => {
    if (isError) {
      console.log(message);
      //localStorage.clear();
    }

    if (!user) {
      /*setTimeout(() => {
        console.log("World!");
      }, 5000);*/
      // navigate("/login");
      console.log("yeetDash");
    }

    dispatch(getMessages());

    /*return () => {
      dispatch(reset());
    };*/
  }, [user]);

  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    console.log("wtf");
    navigate("/");
  };

  return (
    <header className="header">
      <div className="logo">
        <Link to="/">
          <img
            src={tst}
            width="200px"
          />
        </Link>
      </div>
      <ul>
        {/* {user && user.role == "admin" && (
          <>
            <li>
              <Link to="./accounts">
                <FaList /> Accounts
              </Link>
            </li>
            <li>
              <Link to="./">
                <FaDashcube /> Dashboard
              </Link>
            </li>
          </>
        )} */}
        {user && user.role != "admin" && user.role != "user" && (
          <>
            <li>
              <p
                style={{ cursor: "pointer", fontSize: "16px" }}
                onClick={() => {
                  window.open("mailto:distro@thesystemrecords.com");
                }}
              >
                <AiOutlineMail /> Support
              </p>
            </li>
            <li>
              <Link to="./messages">
                <FaBell />
                Messages{" "}
                {messages && messages.length > 0 && (
                  <span style={{ fontSize: "10px" }}>" 🔴"</span>
                )}
              </Link>
            </li>
            <li>
              <Link to="./albums">
                <FaList /> My Albums
              </Link>
            </li>
            <li>
              <Link to="./">
                <FaDashcube /> Submit an Album
              </Link>
            </li>
          </>
        )}
        {user ? (
          <>
            { twoFactorEnabled && user && user.role == "admin" && <li>
              <Link to="./register">
                <FaUser /> Create Accounts
              </Link>
            </li> }
            { twoFactorEnabled && user && user.role == "admin" && <li>
              <Link to="./accounts">
                <FaUser /> Manage Accounts
              </Link>
            </li> }
            { twoFactorEnabled && <li>
              <Link to="./settings">
                <BsFillGearFill /> Settings
              </Link>
            </li>}
            <li>
              <button className="btn" onClick={onLogout}>
                <FaSignOutAlt /> Logout
              </button>
            </li>
          </>
        ) : (
          <>
            <li>
              <Link to="./login">
                <FaSignInAlt /> Login
              </Link>
            </li>
          </>
        )}
      </ul>
    </header>
  );
}

export default Header;
